
.main-container {
    flex-direction: column;
}

.pathPanel {
    border-radius: 0!important;
}

.panelExpanded {
    margin: 16px 0!important;
}
