
.add-organization {

    .dialog-content {
        
        .step-content {
            min-height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;

            .logo-container {

                .logo {
                    width: 120px;
                    height: 120px;
                }

                .drop-zone-component {
                    width: 120px;
                    height: 120px;
                }
            }

            .cover-logo-container {

                .logo {
                    width: 360px;
                    height: 120px;
                }

                .drop-zone-component {
                    width: 360px;
                    height: 120px;
                }
            }
            .docs-logo-container {

                .logo {
                    width: 300px;
                    height: 100px;
                }

                .drop-zone-component {
                    width: 300px;
                    height: 100px;
                }
            }
        }

        .buttons {
            padding: 8px;
            display: flex;
            justify-content: flex-end;

            button {
                margin: 0 4px;
            }
        }
    }
}