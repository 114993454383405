.create-container {
    display: flex;
    flex-direction: row;
    padding: 4px 10px;

    .main-card {
        width: 100%;

        .card-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .header {
                display: flex;
                justify-content: space-between;
            }

            .content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .form-container {
                    padding: 4px 10px;
                    max-width: 50vw;
                    min-width: 43vw;
                }

                .graph-container-card {
                    padding: 4px 10px;
                    width: 45vw;
                    display: flex;
                    flex-direction: column;
                    margin-left: 12px;
                }

                .row {
                    display: flex;
                    flex-direction: row;
                }
            }
        }

        .add-card {
            padding: 2px 0;
        }
    }

}