.settings-screen {

    .content {
        margin: 0 auto;
        margin-top: 20px;
        max-width: 800px;
    }

    .add-card {
        padding: 2px 0;
    }

    .list-item {
        cursor: pointer;

        :hover {
            color: #585858;
        }
    }
}