.organization-screen {
    padding-top: 40px;
    min-width: 500px;

    .content {

        .logo-container {
            min-width: 30vw;
            margin-bottom: 8px;

            .organization-logo {
                height: 180px;
                width: 100%;
                object-fit: contain;
            }
        }

        .groups-container {
            
            .groups-label {
                font-size: 1.2em;
            }

            .groups {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .group-card {
                    width: 150px;
                    height: 150px;
                    padding: 8px;
                }
                
                .add-group-card {
                    cursor: pointer;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    svg {
                        width: 80px;
                        height: 80px;
                        color: #737373;
                    }
                    
                    &:hover {
                        background-color: rgb(155, 154, 154);
                    }
                }   
            }
        }
        .save-container {
            cursor: pointer;
            
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px;
            
            &:hover {
                background-color: rgb(155, 154, 154);
            }
        }
    }
}