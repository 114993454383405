
$primary-color: #44A785;

$phone-width: 640px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin phone {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}


.event-modal {

    .plan-name-container {
        background-color: $primary-color;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .plan-name {
            font-size: 20px;
            font-weight: 600;
            padding: 0 14px;
        }

        .close-button {
            color: white;
            padding: 14px;
        }

        svg {
            cursor: pointer;

            &:hover {
                opacity: .9;
            }
        }
    }

    .event-title {
        padding: 8px 16px;
        color: $primary-color;
        border: 1px solid $primary-color;
        font-size: 20px;
        font-weight: 600;
    }

    .content {
        color: grey;
        padding: 16px 16px;
        width: 40vw;
        max-width: 560px;

        @include phone {
            width: 80vw;
            max-width: 90vw;
        }

        .text-inline {
            display: inline;
            white-space: pre-line;
        }

        .scheduled-to {
            display: flex;
            justify-content: flex-start;

            p {
                color: #373737;
                font-style: oblique;
                font-size: .9em;
            }

            .text-inline {
                display: inline;
            }

            svg {
                color: #373737;
                margin: 0 8px;
            }
        }
    }

    .actions-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 8px 16px 20px;

        @include phone {
            display: flex;
            justify-content: center;
        }

        .action-button {
            border: 1px solid $primary-color;
            color: $primary-color;
            justify-self: stretch;
            margin: 0 10%;
            border-radius: 0;

            @include phone {
                font-size: 14px;
                margin: 0 6px;
            }
        }
    }
}