
.main-container {
    flex-direction: column;

    &>.row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .event-title-input {
            min-width: 35%;
            flex-grow: 1;
        }
    }
}
.event-box-border {
    border: 1px solid #d0c8c8;
    border-radius: 6px;
    padding: 0 12px 4px;
    margin: 6px 0;
    position: relative;
}

.event-box-label {
    position: absolute;
    background-color: white;
    transform: translate(-5px, -11px) scale(0.75);
    padding: 0 7px;
    font-size: 18px;
    font-style: italic;
    color: #b3abab;
}

.start-label {
    padding: 0 8px;
    line-height: 33px;
    font-size: 15px;
}

.remove-icon {
    position: absolute;
    right: -15px;
    top: 0;
    transform: translate(-4px, -10px) scale(0.80);
    background-color: white;
    cursor: pointer;

    :hover {
        color: gray;
    }
}

.eventPanel {
    border-radius: 0!important;
}

.panelExpanded {
    margin: 16px 0!important;
}

.event-box-border .dependency-box:first-child {
    margin-top: 16px;
}

.dependency-box {
    box-shadow: 1px 1px 2px #e5e5e5, -1px -1px 2px #ededed;
    position: relative;
    border-radius: 6px;
    padding: 4px 7px .5px;
    margin: 10px 0;
}

