
.organization-card {
    margin: 8px 0;

    .content {    
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 16px 8px !important;
        margin-top: 0;

        img {
            width: 80px;
            height: 80px;
            margin: 0 8px;
        }
    }
}
