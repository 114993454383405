
.modal {
    background-color: white;
    margin: 20% 10%;
    padding: 28px;
}

.delete-modal {
    background-color: white;
    padding: 28px;
    width: 400px;
    margin: 30vh auto;

    .body {
        padding: 20px 10px;
    }

    .actions {
        display: flex;
        justify-content: flex-ent;
    }
}
.card-container {
    width: 35vw;
}
