.graph-container {
    position: fixed;
    height: 95vh;
    width: 45vw;
    right: 16px;

    .buttons {
        position: fixed;
        right: 40px;
        top: 160px;
        z-index: 8;

        &>div {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-bottom: 6px;
            box-shadow: 1px 1px 1px #979a97, -1px -1px 4px #d5d5d5de;
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
                opacity: .9;
            }

        }
    }
}