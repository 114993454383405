.permissions-modal {

    .MuiPaper-root {
        max-width: unset;
    }

    .content {
        width: 600px;
        max-width: 90vw;
        display: flex;
        flex-direction: column;

        .event-box-border {
            margin: 10px 0;

            .permissions {
                display: flex;
                flex-direction: column;
                max-height: 40vh;
                overflow: auto;

                .permission {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            .add-permission {
                display: flex;
                justify-content: space-between;

                .add-user-button {
                    width: 120px;
                }
            }
        }
    }
}