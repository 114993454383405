.auth-screen {
    height: 100%;
    padding-top: 18vh;

    .tab-content {
        padding: 24px;

        .input {
            margin: 8px 0;
        }

        .or-divider {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid #c1c1c1;
            margin: 14px 0;

            span {
                position: absolute;
                font-size: 16px;
                bottom: -8px;
                background-color: white;
                padding: 0 8px;
                font-style: italic;
                font-weight: 100;
            }
        }

        .login-button {
            padding: 10px 0;
        }

        .welcome-message {
            width: 466px;
            color: #44A785;
            text-align: center;
        }
    }
}

.user-navbar {
    display: flex;
    align-items: center;
    border: .4px solid #969696;
    border-radius: 17px;
    padding: 2px 3px;
    box-shadow: 0.2px 0.2px 1px #969292;
    cursor: pointer;

    &:hover {
        opacity: .9;
    }

    .user-name {
        margin: 0 10px;
        font-style: oblique;
    }

    .avatar {
        width: 30px;
        height: 30px;
    }
}